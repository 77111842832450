import {useEffect, useState} from "react";
import {useToken} from "../hooks/useToken";
import feather from "feather-icons";
import {LinearProgress} from "@mui/joy";
import * as React from "react";

export const loadedPage = (Component, url) => (props) => {
    const [content, setContent] = useState(null);
    const [loading, setLoading] = useState(true);
    const {authenticatedFetch} = useToken();

    const refresh = async () => {
        setLoading(true);
        
        const response = await authenticatedFetch(url, {method: 'GET'});
        
        const data = await response.json();
        setContent(data);
        feather.replace();
        setLoading(false);
    }

    useEffect(() => {
        refresh();
    }, []);

    return (<div>
        {loading ? <LinearProgress thickness={1}/> : <></>}
        <Component content={content} refresh={refresh} {...props} />
    </div>);
}