import {
    Autocomplete,
    Box,
    Button,
    ButtonGroup,
    Card,
    CardContent,
    Menu, MenuItem,
    Link,
    Stack,
    styled, Switch,
    Typography, Tooltip
} from "@mui/joy";
import {
    AlertTriangle,
    Check,
    ExternalLink, Inbox,
    Package,
    PlayCircle,
    RefreshCw,
    StopCircle
} from "react-feather";
import {useRef, useState} from "react";
import {useToken} from "../hooks/useToken";
import {toast} from "sonner";

const DeployIcon = styled(RefreshCw)({
    color: '#EA9A3E',
    animation: 'rotate 2s linear infinite', // 2s duration, linear timing, infinite loop
    '@keyframes rotate': {
        '0%': {
            transform: 'rotate(0deg)',
        },
        '100%': {
            transform: 'rotate(360deg)',
        },
    },
});

export const RepositoryCard = ({short, name, service, branches, canEdit}) => {
    const [locked, setLocked] = useState(service?.isLocked ?? false);
    const [branchDisabled, setBranchDisabled] = useState(false);
    const [deployDisabled, setDeployDisabled] = useState(false);
    const [startStopDisabled, setStartStopDisabled] = useState(false);
    const [open, setOpen] = useState(false);

    const anchorRef = useRef(null);
    const actionRef = useRef(null);

    const {authenticatedFetch} = useToken();

    const stop = (subService) => {
        if (!canEdit) {
            return;
        }
        setStartStopDisabled(true);
        authenticatedFetch(`/api/environments/${short}/${subService}/stop`, {
            method: 'POST',
        }).then(async (response) => {
            setStartStopDisabled(false);
        }).catch(() => {
            setStartStopDisabled(false);
            toast('Failed to stop service', {type: 'error'});
        });
    }

    const start = (subService) => {
        if (!canEdit) {
            return;
        }
        setStartStopDisabled(true);
        authenticatedFetch(`/api/environments/${short}/${subService}/start`, {
            method: 'POST',
        }).then(async (response) => {
            setStartStopDisabled(false);
        }).catch(() => {
            setStartStopDisabled(false);
            toast('Failed to start service', {type: 'error'});
        });
    }

    const deploy = () => {
        if (!canEdit) {
            return;
        }
        setDeployDisabled(true);
        authenticatedFetch(`/api/environments/${short}/${name}/deploy`, {
            method: 'POST',
        }).then(async (response) => {
            setDeployDisabled(false);
        }).catch(() => {
            setDeployDisabled(false);
            toast('Failed to deploy', {type: 'error'});
        });
    }

    const lockBranch = (val) => {
        if (!canEdit) {
            return;
        }
        setLocked(val);
        authenticatedFetch(`/api/environments/${short}/${name}/lock`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(val)
        }).catch(() => {
            toast('Failed to lock branch', {type: 'error'});
            setLocked(!val);
        });
    }

    const switchBranch = (branch) => {
        if (!canEdit) {
            return;
        }
        setBranchDisabled(true);
        authenticatedFetch(`/api/environments/${short}/${name}/branch`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(branch)
        }).then(async (response) => {
            setBranchDisabled(false);
        }).catch(() => {
            toast('Failed to switch branch', {type: 'error'});
            setBranchDisabled(false);
        });
    }

    const actionButtons = [];

    if (canEdit) {
        actionButtons.push(<Button startDecorator={<Package/>} loading={service?.status === 2} loadingPosition="end"
                                   onClick={deploy} disabled={deployDisabled}>
            Deploy
        </Button>);
    }

    let servicesMenu;

    if (canEdit && service?.subServices) {
        if (Object.keys(service.subServices).length > 1) {
            const services = Object.keys(service.subServices);
            actionButtons.push(<Button
                onMouseDown={() => {
                    actionRef.current = () => setOpen(!open);
                }}
                onKeyDown={() => {
                    actionRef.current = () => setOpen(!open);
                }}
                onClick={() => {
                    actionRef.current?.();
                }}
            >Services</Button>);

            servicesMenu = <Menu open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current}>
                {services.map((subService) => (
                    <MenuItem
                        key={subService}
                        onClick={() => service.subServices[subService] === 0 ? start(subService) : stop(subService)}
                    >
                        {service.subServices[subService] === 0 ? <PlayCircle/> :
                            <StopCircle/>}{service.subServices[subService] === 0 ? "Start" : "Stop"} {subService}
                    </MenuItem>
                ))}
            </Menu>;

        } else if (Object.keys(service.subServices).length === 1) {
            const subService = Object.keys(service.subServices)[0];
            const status = service.subServices[subService];

            let servicesButton;
            if (status === 0) {
                servicesButton = <Button startDecorator={<PlayCircle/>} onClick={() => start(subService)}
                                         disabled={startStopDisabled}>Start</Button>;
            } else {
                servicesButton = <Button startDecorator={<StopCircle/>} onClick={() => stop(subService)}
                                         disabled={startStopDisabled}>Stop</Button>
            }
            actionButtons.push(servicesButton);
        }
    }

    if (service?.subDomain) {
        actionButtons.push(<Button component="a" href={service.subDomain} target="_blank"><ExternalLink/></Button>);
    }

    return <Card sx={{height: '100%', width: '100%'}}>
        <CardContent sx={{justifyContent: 'flex-end'}}>
            <Stack direction="row" justifyContent="space-between" marginBottom={2}>
                <Box display="flex" alignItems="flex">
                    <Typography level="title-lg" textColor="#fff" marginRight={1}>
                        {name}
                    </Typography>
                    {service?.deployStatus === 0 ?
                        <Tooltip title="Successfully deployed" color="neutral" placement="top" variant="soft"><Link
                            href={service.runUrl} target="_blank"><Check color="#1F7A1F"/></Link></Tooltip> : <></>}
                            {service?.deployStatus === 1 ?
                                <Tooltip title="Deploying..." color="neutral" placement="top" variant="soft"><Link
                                    href={service.runUrl} target="_blank"><DeployIcon/></Link></Tooltip> : <></>}
                            {service?.deployStatus === 2 ?
                                <Tooltip title="Deployment failed" color="neutral" placement="top" variant="soft"><Link
                                    href={service.runUrl} target="_blank"><AlertTriangle
                                    color="#C41C1C"/></Link></Tooltip> : <></>}
                            {service?.deployStatus === 3 ?
                                <Tooltip title="Deployment in queue" color="neutral" placement="top"
                                         variant="soft"><Inbox /></Tooltip> : <></>}
                            <Typography level="body-sm" textColor="#fff" marginLeft={1}>
                                {service?.commit}
                            </Typography>
                        </Box>
                        <Switch
                            disabled={!canEdit}
                            checked={locked}
                            color={locked ? 'success' : 'neutral'}
                            variant={locked ? 'solid' : 'outlined'}
                            startDecorator={locked ? 'Locked' : 'Unlocked'}
                            onChange={(event) =>
                                lockBranch(event.target.checked)
                            }
                            slotProps={{
                                endDecorator: {
                                    sx: {
                                        minWidth: 24,
                                    },
                                },
                            }}
                        />
                        </Stack>
                        <Stack spacing={1}>
                    <Autocomplete
                        placeholder="dev"
                        options={branches}
                        value={service?.branch}
                        onChange={(evt, val) => {
                            switchBranch(val);
                        }}
                        disabled={branchDisabled || !canEdit}
                    />
                    <ButtonGroup
                        disabled={false}
                        orientation="horizontal"
                        variant="soft"
                        color="primary"
                        buttonFlex={1}
                        ref={anchorRef}
                    >
                        {actionButtons}
                    </ButtonGroup>
                    {servicesMenu}
            </Stack>
        </CardContent>
    </Card>
}