import {extendTheme} from "@mui/joy/styles";

export const theme = extendTheme({
    "colorSchemes": {
        "light": {
            "palette": {}
        },
        "dark": {
            "palette": {
                "background": {},
                "primary": {
                    "300": "#94d6f0",
                    "400": "#7cd2e1",
                    "500": "#63CDD2",
                    "600": "#4b9ba7",
                    "700": "#32687d",
                    "800": "#282135",
                    "900": "#010327"
                }
            }
        }
    },
    zIndex: {
        popup: 1500
    }
})