import {loadedPage} from "../components/loadedPage";
import {Box, Button, Stack, Table, Typography} from "@mui/joy";
import * as React from "react";
import {useToken} from "../hooks/useToken";
import {useState} from "react";
import {toast} from "sonner";
import {RefreshCw} from "react-feather";

const Component = ({content, refresh}) => {
    const {authenticatedFetch} = useToken();
    const [creatingAll, setCreateAll] = useState(false);
    const [creating, setCreate] = useState({});
    const [resetting, setReset] = useState({});
    const [deploying, setDeploy] = useState({});

    const createDeploy = async (repo) => {
        if (creatingAll === true) {
            return;
        }

        if(!repo) {
            setCreateAll(true);
            await authenticatedFetch('api/release/create', {method: 'POST'});
            setCreateAll(false);
        } else {
            setCreate({[repo]: true, ...creating});
            await authenticatedFetch(`api/release/create/${repo}`, {method: 'POST'});
            setCreate({[repo]: false, ...creating});
        }

        await refresh();
    }

    const reset = async (repo) => {
        if (resetting[repo] === true) {
            return;
        }

        setReset({[repo]: true, ...resetting});
        
        await authenticatedFetch(`api/release/reset/${repo}`, {method: 'POST'});

        setReset({[repo]: false, ...resetting});

        await refresh();
    }

    const deploy = async (repo) => {
        if (deploying[repo] === true) {
            return;
        }

        setDeploy({[repo]: true, ...deploying});

        const response = await authenticatedFetch(`api/release/deploy/${repo}`, {method: 'POST'});

        if(!response.ok) {
            toast.error(await response.text());
        }
        setDeploy({[repo]: false, ...deploying});

        await refresh();
    }

    const renderStatus = (status) => {
        switch (status) {
            case 0:
            default:
                return (<Typography textAlign="center" variant="outlined" color="primary">Not Ready</Typography>);

            case 1:
                return (<Typography textAlign="center" variant="outlined" color="warning">Ready</Typography>);

            case 2:
                return (<Typography textAlign="center" variant="outlined" color="success">Deployed</Typography>);
        }
    }

    return (<Stack spacing={1}>
        <Typography level="h1">Release</Typography>
        <Box size="sm" sx={{justifyContent: "flex-end", display: "flex", gap: 1}}>
            <Button size="sm" onClick={() => refresh()}><RefreshCw /></Button>
            <Button loading={creatingAll} onClick={() => createDeploy()}>Create</Button>
            <Button loading={resetting['all']} color="warning" onClick={() => reset('all')}>Reset</Button>
            <Button loading={deploying['all']} color="danger" onClick={() => deploy('all')} disabled={content?.status !== 1}>Deploy</Button>
        </Box>
        <Table
            size="lg"
            stickyHeader
            borderAxis="xBetween">
            <thead>
            <tr>
                <th style={{width: '40%'}}>Repository</th>
                <th style={{width: '7em'}}>Status</th>
                <th>Last commit</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            {content?.repositories.map(x =>
                (<tr key={x.repository}>
                    <td>{x.repository}</td>
                    <td>{renderStatus(x.status)}</td>
                    <td>{new Date(x.lastUpdate).toLocaleString()}</td>
                    <td><Button loading={creating[x.repository]} size="sm" disabled={x.status === 1}
                                onClick={() => createDeploy(x.repository)}>Create</Button>&nbsp;
                        <Button loading={resetting[x.repository]} size="sm" color="warning" disabled={x.status !== 1}
                                onClick={() => reset(x.repository)}>Reset</Button>&nbsp;
                         <Button loading={deploying[x.repository]} size="sm" color="danger" disabled={x.status !== 1}
                                onClick={() => deploy(x.repository)}>Deploy</Button>
                    </td>
                </tr>)
            )}
            </tbody>
        </Table>
    </Stack>);
}

export const ReleasePage = loadedPage(Component, 'api/release');