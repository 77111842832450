import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
    Box, Button, ButtonGroup,
    Card,
    CardContent,
    CardCover,
    CircularProgress, Input,
    Stack,
    Typography
} from "@mui/joy";
import IconButton from "@mui/joy/IconButton";
import {AlignLeft, Copy, Trash} from "react-feather";
import {RepositoryCard} from "../components/RepositoryCard";
import {toast} from "sonner";
import {useEffect, useState} from "react";
import {useToken} from "../hooks/useToken";
import {Dialog} from "../components/Dialog";
import {useSignalR} from "../hooks/useSignalR";
import {useMsal} from "@azure/msal-react";

export const EnvironmentDetailPage = () => {
    const {id} = useParams();
    const {state} = useLocation();
    const {authenticatedFetch} = useToken();
    const navigate = useNavigate();

    const [env, setEnv] = useState({});
    const [branches, setBranches] = useState([]);
    const [deleteDialog, setDeleteDialog] = useState(false);

    const {accounts} = useMsal();

    const canEdit = accounts[0].username === env.lockedBy || accounts[0].username === 'jorg@virtualdays.com' || accounts[0].username === 'l-o@virtualdays.com';

    useSignalR({
        onReceive: (message) => {
            if (message.short === id) {
                console.log(message);
                setEnv(message);
            }
        }
    });

    useEffect(() => {
        if (state) {
            setEnv(state.env);
            setBranches(state.branches);

            return;
        }

        authenticatedFetch(`/api/environments/${id}`).then(async (response) => {
            const json = await response.json();
            setEnv(json.environment);
            setBranches(json.branches);
        });

    }, [])

    if (!env || !branches) {
        return <></>
    }

    const deleteAction = () => {
        authenticatedFetch(`/api/environments/${env.short}`, {
            method: 'DELETE',
        }).then(async (response) => {
            if (!response.ok) {
                toast('Failed to delete environment', {type: 'error'});
                return;
            }

            toast('Started deletion of environment');
            navigate('/environments');
        });
    }

    if (env?.status === 0) {
        const loaded = env.creationStep / 4 * 100;

        return <>
            <Box display="flex" justifyContent="center" alignItems="center" marginTop={15}>
                <Stack alignItems="center" justifyContent="center" spacing={2}>
                    <CircularProgress determinate value={loaded} thickness={2} color="warning"
                                      sx={{'--CircularProgress-size': '120px'}}>
                        {env.creationStep + 1} / 4
                    </CircularProgress>
                    <Typography>Creating {env.name}...</Typography>
                    <Button color="danger" onClick={deleteAction} hidden={!canEdit}>Delete</Button>
                </Stack>
            </Box>
        </>
    }

    const actionButtons = [
        <Button startDecorator={<AlignLeft/>}
                onClick={() => window.open(`https://app.datadoghq.eu/logs?query=env%3A${env.short}&cols=env%2Cservice&index=%2A&messageDisplay=inline&refresh_mode=sliding&stream_sort=desc&viz=stream&live=true`, '_blank').focus()}>
            Logs
        </Button>];

    if (canEdit) {
        actionButtons.push(
            <Button hidden={!canEdit} startDecorator={<Trash/>} color="danger"
                    onClick={() => setDeleteDialog(true)}>
                Delete
            </Button>);
    }

    return <>
        <Dialog title="Delete Environment" actionText="Delete" show={deleteDialog}
                onClose={() => setDeleteDialog(false)} onAction={deleteAction}>
            Are you sure you want to delete {env.name}?
        </Dialog>
        <Box display="grid" sx={{gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))', gridGap: '10px'}}>
            <Box sx={{gridRow: 'span 2', height: '330px', width: '340px'}}>
                <Card sx={{height: '100%', width: '100%'}}>
                    <CardCover>
                        <img
                            src={`/avatars/${env.short}.jpg`}
                            loading="lazy"
                            alt=""
                        />
                    </CardCover>
                    <CardCover
                        sx={{
                            background:
                                'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 400px),linear-gradient(to top, rgba(0,0,0,0.9), rgba(0,0,0,0) 400px)',
                        }}
                    />
                    <CardContent sx={{justifyContent: 'flex-end'}}>
                        <Typography level="title-lg" textColor="#fff">
                            {env.name}
                        </Typography>
                        <Typography textColor="neutral.300">
                            Owner: {env.lockedByDisplayName}
                        </Typography>
                        <Typography textColor="neutral.300">
                            Username: {env.short}
                        </Typography>
                        <Typography textColor="neutral.300">
                            Password: {env.short}
                        </Typography>
                        <Typography textColor="neutral.300">
                            Database Name: env-{env.short}
                        </Typography>
                        <Stack direction="row">
                            <Typography textColor="neutral.300" marginTop={1}>
                                MySQL:
                            </Typography>
                            <Input
                                sx={{'--Input-decoratorChildHeight': '35px', 'width': '100%', 'margin-left': '2px'}}
                                type="text"
                                value="dev-db-a407.mysql.database.azure.com"
                                readOnly
                                size="sm"
                                endDecorator={
                                    <IconButton
                                        variant="solid"
                                        color="primary"
                                        type="submit"
                                        sx={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                                        onClick={() => {
                                            navigator.clipboard.writeText("dev-db-a407.mysql.database.azure.com").then(() => {
                                                toast('Copied to clipboard')
                                            });
                                        }}>
                                        <Copy/>
                                    </IconButton>
                                }
                            />
                        </Stack>
                        <Stack direction="row">
                            <Typography textColor="neutral.300" marginTop={1}>
                                Postgres:
                            </Typography>
                            <Input
                                sx={{'--Input-decoratorChildHeight': '35px', 'width': '100%', 'margin-left': '2px'}}
                                type="text"
                                value="dev-manufactura.postgres.database.azure.com"
                                readOnly
                                size="sm"
                                endDecorator={
                                    <IconButton
                                        variant="solid"
                                        color="primary"
                                        type="submit"
                                        sx={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                                        onClick={() => {
                                            navigator.clipboard.writeText("dev-manufactura.postgres.database.azure.com").then(() => {
                                                toast('Copied to clipboard')
                                            });
                                        }}>
                                        <Copy/>
                                    </IconButton>
                                }
                            />
                        </Stack>
                        <ButtonGroup
                            disabled={false}
                            orientation="horizontal"
                            variant="soft"
                            color="primary"
                            buttonFlex={1}
                        >{actionButtons}
                        </ButtonGroup>
                    </CardContent>
                </Card>
            </Box>
            {Object.keys(branches).map((serviceName) =>
                <Box key={serviceName} sx={{height: '160px', width: '340px'}}>
                    <RepositoryCard canEdit={canEdit} short={env.short} branches={branches[serviceName]}
                                    name={serviceName}
                                    service={env.services[serviceName]}/>
                </Box>
            )}
        </Box>
    </>
}