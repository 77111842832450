import {HubConnectionBuilder} from "@microsoft/signalr";
import {createContext, useContext, useEffect, useState} from "react";

const signalRContext = createContext(null);

export const SignalRProvider = ({children}) => {
    const [connection, setConnection] = useState(null);

    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl(`/hubs/environment-detail`)
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection
                .start()
                .catch((error) => console.log(error));
        }
    }, [connection]);

    return (
        <signalRContext.Provider value={connection}>
            {children}
        </signalRContext.Provider>
    )
}

export const useSignalR = ({onReceive}) => {
    const connection = useContext(signalRContext);

    useEffect(() => {
        if(onReceive) {
            connection?.off("ReceiveMessage");
            connection?.on("ReceiveMessage", onReceive);
        }
    }, [connection]);

    return {connection};
}