import {useMsal} from "@azure/msal-react";
import {loginRequest} from "../authConfig";

export const useToken = () => {
    const {instance, accounts} = useMsal();

    const getToken = async () => {
        const token = await instance.acquireTokenSilent({...loginRequest, account: accounts[0]});
        
        return token.accessToken;
    }
    
    const authenticatedFetch = async (resource, options) => {
        const headers = new Headers(options?.headers);
        const bearer = `Bearer ${await getToken()}`;

        headers.set('Authorization', bearer);
        
        return fetch(resource, {
            ...options,
            headers
        });
    }
    
    return { getToken, authenticatedFetch };
}