import {
    Autocomplete,
    Avatar,
    Box,
    Button, DialogTitle,
    FormControl,
    FormLabel,
    Modal,
    ModalClose, ModalDialog,
    Stack,
    Table,
    Typography, useTheme
} from "@mui/joy";
import * as React from "react";
import {loadedPage} from "../components/loadedPage";
import {useToken} from "../hooks/useToken";
import {useMsal} from "@azure/msal-react";
import {useNavigate} from "react-router-dom";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import {toast} from "sonner";

const Component = ({content, refresh}) => {
    const [open, setOpen] = React.useState(null);
    const {authenticatedFetch} = useToken();
    const {accounts} = useMsal();
    const theme = useTheme();

    const navigate = useNavigate();

    const saveMappings = async () => {

        const response = await authenticatedFetch(`/api/environments`, {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(open.branches)
        });

        if (!response.ok) {
            toast('Failed to create environment', {type: 'error'});
            return;
        }

        var json = await response.json();

        navigate(`/environment/${json.short}`, {state: {branches: content.branches, env: json}});
    }

    const addEnvironment = async () => {
        setOpen({
            branches: {}
        });
    }

    const forceDelete = async () => {
        const envName = prompt('Environment name?');

        authenticatedFetch(`/api/environments/${envName}/force`, {
            method: 'DELETE',
        }).then(async (response) => {
            if (!response.ok) {
                toast('Failed to delete environment', {type: 'error'});
                return;
            }

            toast('Started deletion of environment');
        });
    }

    const onModalClose = () => {
        setOpen(null);
    }

    const onClickRow = (row) => {
        navigate(`/environment/${row.short}`, {state: {branches: content.branches, env: row}});
    }

    return (
        <>
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={open}
                onClose={() => onModalClose()}
                sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            >
                <ModalDialog>
                    <ModalClose/>
                    <DialogTitle>Create environment</DialogTitle>
                    <List
                        sx={{
                            overflow: 'scroll',
                            mx: 'calc(-1 * var(--ModalDialog-padding))',
                            px: 'var(--ModalDialog-padding)',
                        }}
                    >
                        {open ? Object.keys(content?.branches).map(repo => (
                            <ListItem key={`create-${repo}`}>
                                <FormControl>
                                    <FormLabel>{repo}</FormLabel>
                                    <Box sx={{display: 'flex', py: 1}}>
                                        <Autocomplete
                                            placeholder="dev"
                                            options={content?.branches[repo]}
                                            value={open?.branches[repo]}
                                            onChange={(evt, val) => setOpen({
                                                ...open,
                                                branches: {...open?.branches, [repo]: val}
                                            })}
                                        />
                                    </Box>
                                </FormControl>
                            </ListItem>
                        )) : <></>}
                    </List>
                    <FormControl>
                        <Button size="md" color="success" onClick={() => saveMappings()}>Save</Button>
                    </FormControl>
                </ModalDialog>
            </Modal>
            <Stack spacing={0.5}>
                <Box sx={{
                    display: 'flex', alignItems: 'end',
                    py: 1,
                    pl: {sm: 2},
                    pr: {xs: 1, sm: 1}
                }}>
                    <Typography level="h1" sx={{flex: '1 1 100%'}}>Environments</Typography>
                    {accounts[0].username === 'jorg@virtualdays.com' || accounts[0].username === 'l-o@virtualdays.com' ?
                        <Button sx={{width: "12em", marginRight: '.5em'}} size="md" color="danger" variant="solid"
                                disabled={!content}
                                onClick={() => forceDelete()}>Force delete</Button> : <></>}
                    <Button sx={{width: "14em"}} size="md" color="success" variant="solid" disabled={!content}
                            onClick={() => addEnvironment()}>New environment</Button>
                </Box>
                <Table stickyHeader aria-label="environments">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Owner</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {content?.envs.map((row) => (
                        <tr key={row.name} style={{
                            cursor: "pointer",
                            backgroundColor: row.lockedBy === accounts[0].username ? theme.vars.palette.primary["800"] : null
                        }} onClick={() => onClickRow(row)}>
                            <td>
                                <Box sx={{display: 'flex', alignItems: 'center', gap: 1.5}}>
                                    <Avatar src={`/avatars/${row.short}.jpg`}/>
                                    <Box sx={{minWidth: 0}}>
                                        <Typography noWrap fontWeight="lg">
                                            {row.name}
                                        </Typography>
                                    </Box>
                                </Box>
                            </td>
                            <td>
                                <Typography>{row.lockedByDisplayName}</Typography>
                            </td>
                            <td>
                                {row.status === 0 ? <Typography color="warning">Creating</Typography> : <></>}
                                {row.status === 1 ? <Typography color="success">Running</Typography> : <></>}
                                {row.status === 2 ? <Typography color="danger">Deleting</Typography> : <></>}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Stack>
        </>);
}

export const EnvironmentsPage = loadedPage(Component, 'api/environments');