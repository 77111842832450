/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Divider from '@mui/joy/Divider';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import { closeSidebar } from '../utils';
import {useMsal} from "@azure/msal-react";
import { Link } from 'react-router-dom';
import {VDLogo} from "./VDLogo";

export const NavMenu = ({routes}) => {
    const {accounts} = useMsal();
    
    return (
        <Sheet
            className="Sidebar"
            sx={{
                position: {
                    xs: 'fixed',
                    md: 'sticky',
                },
                transform: {
                    xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
                    md: 'none',
                },
                transition: 'transform 0.4s, width 0.4s',
                zIndex: 10000,
                height: '100dvh',
                width: 'var(--Sidebar-width)',
                top: 0,
                p: 1.5,
                py: 3,
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRight: '1px solid',
                borderColor: 'divider',
            }}
        >
            <GlobalStyles
                styles={(theme) => ({
                    ':root': {
                        '--Sidebar-width': '224px',
                        [theme.breakpoints.up('lg')]: {
                            '--Sidebar-width': '256px',
                        },
                    },
                })}
            />
            <Box
                className="Sidebar-overlay"
                sx={{
                    position: 'fixed',
                    zIndex: 9998,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    bgcolor: 'background.body',
                    opacity: 'calc(var(--SideNavigation-slideIn, 0) - 0.2)',
                    transition: 'opacity 0.4s',
                    transform: {
                        xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
                        lg: 'translateX(-100%)',
                    },
                }}
                onClick={() => closeSidebar()}
            />
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <VDLogo sx={{width: "4em", height: "4em"}} />
                <Typography fontFamily="'Poppins', sans-serif" fontWeight="xl">manufactura</Typography>
            </Box>
            <Box
                sx={{
                    minHeight: 0,
                    overflow: 'hidden auto',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <List
                    sx={{
                        '--ListItem-radius': '8px',
                        '--List-gap': '4px',
                        '--List-nestedInsetStart': '40px',
                    }}
                >
                    {routes.map((route, index) => {
                        const { title, icon, path, hidden } = route;
                        if (hidden) {
                            return null;
                        }
                        
                        const isLink = path.includes('http');
                        return (<ListItem key={path}>
                            <ListItemButton component={isLink ? "a" : Link} to={isLink ? null : path} href={isLink ? path : null} target={isLink ? "_blank" : null}>
                                <ListItemDecorator>
                                    <i data-feather={icon} />
                                </ListItemDecorator>
                                <ListItemContent>{title}</ListItemContent>
                            </ListItemButton>
                        </ListItem>);
                    })}
                </List>
            </Box>
            <Divider />
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Avatar variant="outlined" src="/static/images/avatar/3.jpg" />
                <Box sx={{ minWidth: 0, flex: 1 }}>
                    <Typography fontSize="sm" fontWeight="lg">
                        {accounts[0].name}
                    </Typography>
                    <Typography level="body3">{accounts[0].username}</Typography>
                </Box>
            </Box>
        </Sheet>
    );
}
