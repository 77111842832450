import React, {Component} from 'react';
import {Route, Routes} from 'react-router-dom';
import AppRoutes from './AppRoutes';
import {Layout} from './components/Layout';
import '@fontsource/public-sans';
import {useToken} from "./hooks/useToken";
import {CircularProgress} from "@mui/joy";
import {SignalRProvider} from "./hooks/useSignalR";

export default () => {
    const {authenticatedFetch} = useToken();
    const [pages, setPages] = React.useState([]);

    React.useEffect(() => {
        authenticatedFetch('/api/home')
            .then(response => response.json())
            .then(data => {
                setPages(data);
            });
    }, []);

    if (pages.length === 0) {
        return (<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
            <CircularProgress size="lg" thickness={1}/>
        </div>);
    }

    const routes = AppRoutes.filter(route => route.hidden || pages.some(page => page.toLowerCase() === route.title.toLowerCase()));
    const routeWithComponents = routes.filter(route => route.element);

    return (
        <SignalRProvider>
            <Layout routes={routes}>
                <Routes>
                    {routeWithComponents.map((route, index) => {
                        const {element, ...rest} = route;
                        return <Route key={index} {...rest} element={element}/>;
                    })}
                </Routes>
            </Layout>
        </SignalRProvider>
    );
}
