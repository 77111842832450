import {ReactComponent as Logo} from "../logo.svg";
import {AspectRatio} from "@mui/joy";

export const VDLogo = ({sx, ...props}) => {
    return (
        <AspectRatio
            ratio="1"
            variant="plain"
            {...props}
            sx={[
                {
                    width: 36,
                    borderRadius: 'sm',
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            <div>
                <Logo/>
            </div>
        </AspectRatio>
    )
}