import {Button, DialogActions, DialogContent, DialogTitle, Modal, ModalDialog} from "@mui/joy";
import Divider from "@mui/joy/Divider";

export const Dialog = ({title, children, show, onClose, actionText, onAction}) => {
    return (
            <Modal open={show} onClose={onClose}>
                <ModalDialog variant="outlined" role="alertdialog">
                    <DialogTitle>
                        {title}
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        {children}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="solid" color="danger" onClick={onAction}>
                            {actionText}
                        </Button>
                        <Button variant="plain" color="neutral" onClick={onClose}>
                            Cancel
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
    );
}