import React from 'react';
import {NavMenu} from './NavMenu';
import {CssBaseline, CssVarsProvider} from "@mui/joy";
import {theme} from "../theme";
import GlobalStyles from "@mui/joy/GlobalStyles";
import Box from "@mui/joy/Box";
import Header from "./Header";
import feather from "feather-icons";
import {Toaster} from "sonner";
import {useLocation} from "react-router-dom";

const useEnhancedEffect =
    typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

export function Layout({routes, children}) {
    const location = useLocation();

    useEnhancedEffect(() => {
        if (typeof feather !== 'undefined') {
            feather.replace();
        }
    }, [location]);

    return (
        <CssVarsProvider disableTransitionOnChange theme={theme} defaultMode="dark">
            <GlobalStyles
                styles={{
                    '[data-feather], .feather': {
                        color: 'var(--Icon-color)',
                        margin: 'var(--Icon-margin)',
                        fontSize: 'var(--Icon-fontSize, 20px)',
                        width: '1em',
                        height: '1em',
                    },
                }}
            />
            <CssBaseline/>
            <Toaster theme="dark"/>
            <Box sx={{display: 'flex', minHeight: '100dvh'}}>
                <Header/>
                <NavMenu routes={routes}/>
                <Box
                    component="main"
                    className="MainContent"
                    sx={(theme) => ({
                        '--main-paddingTop': {
                            xs: `calc(${theme.spacing(2)} + var(--Header-height, 0px))`,
                            md: '32px',
                        },
                        px: {
                            xs: 2,
                            md: 3,
                        },
                        pt: 'var(--main-paddingTop)',
                        pb: {
                            xs: 2,
                            sm: 2,
                            md: 3,
                        },
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 0,
                        height: '100dvh',
                        gap: 1,
                        overflow: 'auto',
                    })}
                >
                    {children}
                </Box>
            </Box>
        </CssVarsProvider>
    );
}
