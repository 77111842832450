import {Button, Card, FormLabel, Grid, Input, Stack, Typography} from "@mui/joy";
import * as React from "react";
import {toast} from "sonner";
import {useToken} from "../hooks/useToken";
import {loadedPage} from "../components/loadedPage";
import {Copy} from "react-feather";

const Component = ({content}) => {
    const {authenticatedFetch} = useToken();

    const downloadFile = async (url, name) => {
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);

        authenticatedFetch(url, { method: 'GET' })
            .then(response => response.blob())
            .then(blob => {
                let objectUrl = window.URL.createObjectURL(blob);

                anchor.href = objectUrl;
                anchor.download = name;
                anchor.click();

                window.URL.revokeObjectURL(objectUrl);
            });
    }

    return (<div>
        <Stack spacing={1}>
            <Typography level="h1">VPN</Typography>
            <Grid container spacing={2} direction="row">
                {content?.map((env) => {
                    return (<Grid key={env.name}><Card variant="outlined" sx={{width: 450}}>
                        <Stack spacing={2}>
                            <Typography level="h2">{env.name}</Typography>
                            <div>
                                <FormLabel
                                    sx={(theme) => ({
                                        '--FormLabel-color': theme.vars.palette.primary.plainColor,
                                    })}
                                >
                                    Kubernetes Context
                                </FormLabel>
                                <Input
                                    sx={{'--Input-decoratorChildHeight': '45px'}}
                                    type="text"
                                    required
                                    value={env.contextCmd}
                                    readOnly
                                    endDecorator={
                                        <Button
                                            variant="solid"
                                            color="primary"
                                            type="submit"
                                            sx={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                                            onClick={() => {
                                                navigator.clipboard.writeText(env.contextCmd);
                                                toast('Copied to clipboard')
                                            }}
                                        >
                                            <Copy />
                                        </Button>
                                    }
                                />
                            </div>
                            <Button
                                variant="solid"
                                size="md"
                                color="primary"
                                onClick={() => downloadFile(env.download, 'vd-' + env.name + '.xml')}
                            >
                                Download
                            </Button>
                        </Stack>
                    </Card></Grid>);
                })}
            </Grid>
        </Stack>
    </div>);
}

export const VpnPage = loadedPage(Component, 'api/vpn');