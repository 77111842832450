import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {InteractionType, PublicClientApplication} from '@azure/msal-browser';
import {MsalAuthenticationTemplate, MsalProvider} from '@azure/msal-react';
import {loginRequest, msalConfig} from './authConfig';
import {StyledEngineProvider} from "@mui/joy";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const msalInstance = new PublicClientApplication(msalConfig);

root.render(
    <React.StrictMode>
        <StyledEngineProvider injectFirst>
            <MsalProvider instance={msalInstance}>
                <MsalAuthenticationTemplate authenticationRequest={loginRequest}
                                            interactionType={InteractionType.Redirect}>
                    <BrowserRouter basename={baseUrl}>
                        <App/>
                    </BrowserRouter>
                </MsalAuthenticationTemplate>
            </MsalProvider>
        </StyledEngineProvider>
    </React.StrictMode>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
