import {HomePage} from "./pages/HomePage";
import {VpnPage} from "./pages/VpnPage";
import {ReleasePage} from "./pages/ReleasePage";
import {EnvironmentsPage} from "./pages/EnvironmentsPage";
import {EnvironmentDetailPage} from "./pages/EnvironmentDetailPage";

const AppRoutes = [
    {
        index: true,
        element: <HomePage/>,
        title: 'Home',
        icon: 'home',
        path: '/'
    },
    {
        element: <EnvironmentsPage />,
        title: 'Environments',
        icon: 'codesandbox',
        path: 'environments'
    },
    {
        path: '/vpn',
        element: <VpnPage/>,
        title: 'VPN',
        icon: 'link-2'
    },
    {
        path: '/release',
        element: <ReleasePage/>,
        title: 'Release',
        icon: 'git-merge'
    },
    {
        path: 'environment/:id',
        title: 'Environment',
        element: <EnvironmentDetailPage/>,
        hidden: true
    },
    {
        path: 'https://netorgft8746005-my.sharepoint.com/:p:/g/personal/jorg_virtualdays_com/Eeio3xyQ8VFHqzOUQhGdy0oBlQkUML3M37ZQJRHZKQO_Qw?e=6KeA4g',
        title: 'Weekly Tech',
        icon: 'align-center',
        element: null
    }
];

export default AppRoutes;
